import React from 'react';
import * as Libs from '../custom-files/Libs';

const amplitudeEvents = (eventName, eventProperties) => {
  const apiKey = '5e87f3ba246d5ece29a71f6460e691c3';

  try {
    //initialize
    Libs.Amplitude.init(apiKey);
    //fire event
    Libs.Amplitude.track(eventName, eventProperties);
  } catch (e) {
    console.log(e);
  }
};

export default amplitudeEvents;
