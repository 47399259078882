import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { ScreenContainer, WebView, withTheme } from '@draftbit/ui';

const StatusTrackerScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer hasSafeArea={false} scrollable={false}>
      <WebView
        allowFileAccessFromFileURLs={false}
        allowUniversalAccessFromFileURLs={false}
        cacheEnabled={true}
        incognito={false}
        javaScriptCanOpenWindowsAutomatically={false}
        javaScriptEnabled={true}
        mediaPlaybackRequiresUserAction={false}
        showsHorizontalScrollIndicator={true}
        showsVerticalScrollIndicator={true}
        startInLoadingState={false}
        {...GlobalStyles.WebViewStyles(theme)['Web View'].props}
        source={{ uri: 'https://status.scienceoncall.com/' }}
        style={StyleSheet.applyWidth(
          GlobalStyles.WebViewStyles(theme)['Web View'].style,
          dimensions.width
        )}
      />
    </ScreenContainer>
  );
};

export default withTheme(StatusTrackerScreen);
