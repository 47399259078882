import { systemWeights } from 'react-native-typography';
export default {
  disabledOpacity: 0.5,
  roundness: 6,
  colors: {
    Background: 'rgb(241, 194, 50)',
    'Custom #f1c232': 'rgb(241, 194, 50)',
    Divider: 'rgba(234, 237, 242, 1)',
    Error: 'rgba(255, 69, 100, 1)',
    Light: 'rgba(165, 173, 183, 1)',
    'Light Inverse': 'rgb(255, 255, 255)',
    Medium: 'rgba(70, 78, 88, 1)',
    'Medium Inverse': 'rgba(255, 255, 255, 0.87)',
    NFT_TIME_Black: 'rgb(0, 0, 0)',
    NFT_TIME_Blue: 'rgb(33, 158, 188)',
    NFT_TIME_Border: 'rgb(153, 153, 153)',
    NFT_TIME_Cyan_White: 'rgb(233, 236, 239)',
    NFT_TIME_Dark_Gray: 'rgb(64, 72, 82)',
    NFT_TIME_Dark_White: 'rgb(241, 241, 241)',
    NFT_TIME_Divider_Dark: 'rgb(151, 151, 151)',
    NFT_TIME_Gray: 'rgb(111, 111, 111)',
    NFT_TIME_Icons: 'rgb(86, 86, 86)',
    NFT_TIME_Light_Blue: 'rgb(142, 202, 230)',
    NFT_TIME_Light_Gray: 'rgb(223, 223, 223)',
    NFT_TIME_Lime_Green: 'rgb(221, 242, 71)',
    NFT_TIME_Primary_Black: 'rgb(16, 16, 1)',
    NFT_TIME_Red: 'rgb(255, 0, 0)',
    NFT_TIME_Stone: 'rgb(207, 207, 207)',
    NFT_TIME_Stone_Gray: 'rgb(183, 183, 183)',
    NFT_Time_Dark_Mode_Link: 'rgb(252, 252, 252)',
    NFT_Time_Green: 'rgb(0, 128, 0)',
    NFT_Time_Hot_Blue: 'rgb(0, 0, 255)',
    NFT_Time_Mac_Gray: 'rgb(128, 128, 128)',
    NFT_Time_Secondary: 'rgb(71, 129, 242)',
    NFT_Time_UI_Black: 'rgb(16, 16, 16)',
    NFT_White_v2: 'rgb(255, 255, 255)',
    Primary: 'rgba(90, 69, 255, 1)',
    Secondary: 'rgba(59, 201, 234, 1)',
    Strong: 'rgb(0, 0, 0)',
    'Strong Inverse': 'rgb(0, 0, 0)',
    Surface: 'rgb(255, 145, 90)',
    background: 'rgb(241, 194, 50)',
    custom_rgb241_194_50: 'rgb(241, 194, 50)',
    divider: 'rgba(234, 237, 242, 1)',
    error: 'rgba(255, 69, 100, 1)',
    light: 'rgba(165, 173, 183, 1)',
    lightInverse: 'rgb(255, 255, 255)',
    medium: 'rgba(70, 78, 88, 1)',
    mediumInverse: 'rgba(255, 255, 255, 0.87)',
    nFTTIMEBlack: 'rgb(0, 0, 0)',
    nFTTIMEBlue: 'rgb(33, 158, 188)',
    nFTTIMEBorder: 'rgb(153, 153, 153)',
    nFTTIMECyanWhite: 'rgb(233, 236, 239)',
    nFTTIMEDarkGray: 'rgb(64, 72, 82)',
    nFTTIMEDarkWhite: 'rgb(241, 241, 241)',
    nFTTIMEDividerDark: 'rgb(151, 151, 151)',
    nFTTIMEGray: 'rgb(111, 111, 111)',
    nFTTIMEIcons: 'rgb(86, 86, 86)',
    nFTTIMELightBlue: 'rgb(142, 202, 230)',
    nFTTIMELightGray: 'rgb(223, 223, 223)',
    nFTTIMELimeGreen: 'rgb(221, 242, 71)',
    nFTTIMEPrimaryBlack: 'rgb(16, 16, 1)',
    nFTTIMERed: 'rgb(255, 0, 0)',
    nFTTIMEStone: 'rgb(207, 207, 207)',
    nFTTIMEStoneGray: 'rgb(183, 183, 183)',
    nFTTimeDarkModeLink: 'rgb(252, 252, 252)',
    nFTTimeGreen: 'rgb(0, 128, 0)',
    nFTTimeHotBlue: 'rgb(0, 0, 255)',
    nFTTimeMacGray: 'rgb(128, 128, 128)',
    nFTTimeSecondary: 'rgb(71, 129, 242)',
    nFTTimeUIBlack: 'rgb(16, 16, 16)',
    nFTWhiteV2: 'rgb(255, 255, 255)',
    primary: 'rgba(90, 69, 255, 1)',
    secondary: 'rgba(59, 201, 234, 1)',
    strong: 'rgb(0, 0, 0)',
    strongInverse: 'rgb(0, 0, 0)',
    surface: 'rgb(255, 145, 90)',
  },
  typography: {
    body1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    body2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    button: {
      ...systemWeights.bold,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 16,
    },
    caption: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 0,
      lineHeight: 16,
    },
    headline1: {
      ...systemWeights.bold,
      fontSize: 60,
      letterSpacing: 0,
      lineHeight: 71,
    },
    headline2: {
      ...systemWeights.bold,
      fontSize: 48,
      letterSpacing: 0,
      lineHeight: 58,
    },
    headline3: {
      ...systemWeights.bold,
      fontSize: 34,
      letterSpacing: 0,
      lineHeight: 40,
    },
    headline4: {
      ...systemWeights.bold,
      fontSize: 24,
      letterSpacing: 0,
      lineHeight: 34,
    },
    headline5: {
      ...systemWeights.bold,
      fontSize: 20,
      letterSpacing: 0,
      lineHeight: 26,
    },
    headline6: {
      ...systemWeights.bold,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 24,
    },
    overline: {
      ...systemWeights.regular,
      fontSize: 12,
      letterSpacing: 2,
      lineHeight: 16,
    },
    subtitle1: {
      ...systemWeights.regular,
      fontSize: 16,
      letterSpacing: 0,
      lineHeight: 26,
    },
    subtitle2: {
      ...systemWeights.regular,
      fontSize: 14,
      letterSpacing: 0,
      lineHeight: 22,
    },
    custom14: {
      fontFamily: 'PoppinsRegular',
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
    custom15: {
      fontFamily: 'PoppinsBold',
      fontSize: 16,
      letterSpacing: 1,
      lineHeight: 16,
    },
  },
};
